import { gql } from "@apollo/client";

export const userWithContractsGQL = gql`
  query user {
    user {
      email
      currentSubscription {
        isActive
        type
        lastExpirationDate
        displayName
      }
      contracts {
        _id
        domains
        users
        admins
        efbAdmins
        isActive
        displayName
        domainsAllowed
        certificates
        requiresContract
        requiresStripe
        setup

        keys {
          key
          createdAt
        }
        webhooks {
          _id
          url
          key
          description
          createdAt
        }
        flightApi

        stripe {
          subscriptions {
            plan {
              id
            }
          }
        }
      }
    }
  }
`;

export const contractUserSearchGQL = gql`
  query contractUserSearch($email: String!, $contractId: String!) {
    contractUserSearch(email: $email, contractId: $contractId)
  }
`;
