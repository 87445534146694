import * as React from "react";

import { Typography, Tabs, Tag } from "antd";

import _ from "lodash";
import ReplaceUsers from "./ReplaceUsers";
import ActiveUsers from "./ActiveUsers";
import AddUsers from "./AddUsers";

export default function Users({ contract }) {
  const deviceNumber = _.chain(contract)
    .get("users", [])
    .filter((user) => user.type === "device")
    .size()
    .value();

  const userNumber = _.chain(contract)
    .get("users", [])
    .filter((user) => user.type !== "device")
    .size()
    .value();

  const itemsUsers = [
    {
      key: "u1",
      label: `Active users (${userNumber})`,
      children: (
        <div className="box">
          <ActiveUsers contract={contract} />
        </div>
      ),
    },
    {
      key: "u2",
      label: "Add users",
      children: (
        <div className="box">
          <AddUsers contract={contract} />
        </div>
      ),
    },
    {
      key: "u3",
      label: "Update entire user list",
      children: (
        <div className="box danger">
          <ReplaceUsers contract={contract} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        This section contains the users having access to CTOT app. You can
        add/remove/update users from your user list. You can do it as many times
        as you want.
        <br />
        <br />
        Each user in the "Active users" list has access to the CTOT app.
        <br />
        <br />
        You can only add users having an email belonging to your registered
        domain name(s), i.e. emails from the following domains:
        <ul>
          {_.chain(contract)
            .get("domainsAllowed")
            .filter((ele) => ele !== "ctot.app")
            .map((ele, i) => (
              <li key={i}>
                <Typography.Text type="secondary">{ele}</Typography.Text>
              </li>
            ))
            .value()}
        </ul>
        <Tag color="green" style={{ marginTop: 10, padding: 10 }}>
          <div>How to login as a user?</div>
          <div style={{ color: "black" }}>
            The user inputs his email in the app and will instantly receive a
            login code/link by email. <br />
            The user clicks on this link or enters this code in the app. That's
            it.
          </div>
        </Tag>
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        //type="card"
        items={itemsUsers}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}
